
.scroll-animation {
  display: flex;
  justify-content: center;
}

.icon-scroll, .icon-scroll:before, .icon-scroll-small, .icon-scroll-small:before {
  position: absolute;
  left: 50%;
}

.icon-scroll {
  width: 34px;
  height: 56px;
  margin-left: -12px;
  bottom: 10%;
  margin-top: -35px;
  box-shadow: inset 0 0 0 1px #565656FF;
  border-radius: 25px;
  opacity: 1;
  transition: opacity .5s ease;
}

.icon-scroll-small {
  width: 32px;
  height: 56px;
  margin-left: -12px;
  bottom: 10%;
  margin-top: -35px;
  box-shadow: inset 0 0 0 1px #565656FF;
  border-radius: 8px;
  opacity: 1;
  transition: opacity .5s ease;
}


.icon-scroll-hidden {
  opacity: 0;
  transition: opacity .5s ease;
}

.icon-scroll:before {
  content: '';
  width: 6px;
  height: 6px;
  background: #565656FF;
  margin-left: -3px;
  top: 8px;
  border-radius: 4px;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: scroll;
}

.icon-scroll-small:before {
  content: '';
  width: 4px;
  height: 4px;
  background: #565656FF;
  margin-left: -2px;
  top: 8px;
  border-radius: 4px;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: scroll;
}

@keyframes scroll {
  0% {
    opacity: 1
  }
  100% {
    opacity: 0;
    transform: translateY(35px)
  }
}