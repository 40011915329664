.carousel .control-next.control-arrow:before {
  border-left: 8px solid rgb(244, 240, 240)
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid rgb(244, 240, 240)
}

.carousel.carousel-slider .control-arrow:hover {
  background: transparent;
}

.carousel .slide {
  background-color: transparent;
}

.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
  cursor: pointer;
}

.carousel .control-dots .dot {
  box-shadow: none;
  background: rgb(244, 240, 240);
}

