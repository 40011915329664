.slick-slide div
{
  border-radius: 8px;
  height: 60vh;
  outline: none;
}

.slick-dots {
  bottom: 20px;
}

.slick-dots.slick-thumb {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.slick-dots.slick-thumb li {
  width: 6px;
  height: 6px;
  margin: 0 3px;
  border-radius: 999px;
  transition: all 0.5s ease-in-out;
  will-change: width;
  background-color: rgba(244, 240, 240,0.5);
}

.slick-dots.slick-thumb li.slick-active {
  background-color: rgb(244, 240, 240);
  width: 16px;
}