#slider_container .slick-slider {
  width: 100%;
}
#slider_container .slick-list {
  height: 100% !important;
}
#slider_container .slick-slide {
  padding: 24px;
}

#slider_container .slick-track {
  height: fit-content !important;
}

#slider_container .slick-track > .slick-slide:first-child {
  margin-top: 48px;
}

#slider_container .slick-slide div {
  height: 100%;
  border-radius: 0;
  display: flex !important;
  justify-content: center;
  align-items: center;
  background-color: rgb(244, 240, 240);
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

#slider_container .slick-slide img {
  height: 100%;
  width: 100%;
  padding: 0 !important;
}

#slider_container .slick-track {
  transform: none !important;
}

.css-yfo96e {
  width: 100vw;
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding-top: 56px !important;
  padding-bottom: 56px !important;
}

.css-ttn1xo{
  overflow: scroll;
}

.css-wrfj5p-MuiPaper-root-MuiAppBar-root {
  box-shadow: 0px -6px 0px -1px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 1px 1px 0px rgb(0 0 0 / 8%) !important;
}

.css-1d2nz1c-MuiPaper-root-MuiAppBar-root {
  box-shadow: 0px -6px 0px -1px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 1px 1px 0px rgb(0 0 0 / 8%) !important;
}

.css-hyum1k-MuiToolbar-root {
  background-color: rgba(244, 240, 240) !important;
}

.css-hyum1k-MuiToolbar-root { min-height: 0px !important;}

.MuiToolbar-root {
  padding-top: 8px;
  padding-bottom: 8px;
}

.image_drawer {
  height: 100% !important;
  min-width: 100%;
  object-fit: cover;
}

.css-h4y409-MuiList-root {
  padding-top: 0px !important;
}

.css-1k455el{
  background-color: rgba(244, 240, 240) !important;
  position: sticky;
  top:0;
  z-index: 1;
}

.css-jm9yps{
  padding-left: 0 !important;
  padding-right: 0 !important;
}