body {
  height: 100vh;
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;*/
  font-family: Helvetica sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: 10; /* Necessary for overflow: hidden to work correctly in Safari */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

* {
  box-sizing: border-box
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  -webkit-appearance: none;
  display: none;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: lightgray;
}

/*@font-face {*/
/*  font-family: 'NeueMachina';*/
/*  src: local('NeueMachina'), url("./assets/fonts/NeueMachina-Regular.otf") format('truetype');*/
/*  font-weight: normal;*/
/*}*/

/*@font-face {*/
/*  font-family: 'NeueMachinaLight';*/
/*  src: local('NeueMachina'), url("./assets/fonts/NeueMachina-Light.otf") format('truetype');*/
/*  font-weight: normal;*/
/*}*/

/*@font-face {*/
/*  font-family: 'NeueMachinaBold';*/
/*  src: local('NeueMachina'), url("./assets/fonts/NeueMachina-Ultrabold.otf") format('truetype');*/
/*  font-weight: bold;*/
/*}*/
