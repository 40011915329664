.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.8
}

.img:hover {
  opacity: 1;
  transition: all 0.2s linear;
}