.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timelineContainer {
  -webkit-box-shadow: -1px 2px 16px 0 rgba(0, 0, 0, 0.37);
  -moz-box-shadow: -1px 2px 16px 0 rgba(0, 0, 0, 0.37);
  box-shadow: -1px 2px 16px 0 rgba(0, 0, 0, 0.37);
  background-color: rgb(244, 240, 240);
}

.timelineObserverContainer {
  width: 100vw;
  height: 100%;
  position: relative;
  background-color: rgb(244, 240, 240);
}

.timeline {
  height: 70px;
  width: 3px;
  background-color: #e5e5e5;
  margin-top: 16px;
}

.stub1 {
  line-height: 300px;
  font-size: 24px;
  background-color: #eae4e4;
}

.stub2 {
  height: 1500px;
}

.circle {
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 50%;
  background-color: #e5e5e5;
}

.circleWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.topic {
  padding: 18px;
}

.timelineStep {
  animation: fade_in_show 0.5s
}

.legend {
  position: relative;
  padding: 16px;
  padding-top: 32px;
}

@keyframes fade_in_show {
  from {
    opacity: 0.2;
  }

  to {
    opacity: 1;
  }
}
